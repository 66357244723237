import React from "react";
import styles from "./uiux.module.css";
import IT_services_banner_content from "../Common/IT_services_banner_content";
import uiux_card_image from "./UIUX_Images/uiux_card_image.png";

const UIUX_collaborate = () => {
  const first_line = () => {
    return (
      <>
        Unleash the full potential of your digital presence with
        <strong> Novity's </strong>
        UI/UX design services.
      </>
    );
  };
  const second_line =
    "Our talented designers bring years of expertise to every project, ensuring your interfaces are not just visually appealing, but intuitive and engaging.";

  const third_line = () => {
    return (
      <>
        Whether you're a fresh startup or a seasoned enterprise, our custom
        solutions are meticulously crafted to enhance user experiences, drive
        engagement, and elevate your brand's online presence. <br />
        <p style={{ color: "white" }}>
          With Novity, your UI/UX design isn't just an element—it's a strategic
          asset for fueling growth and success in the digital realm.
        </p>
      </>
    );
  };

  const quotes_grid = [
    {
      quotes_heading: "Responsive Design: ",
      quotes_content:
        " Developing interfaces that adapt seamlessly to various devices and screen sizes, providing a consistent experience across platforms.",
    },
    {
      quotes_heading: "Intuitive Interface: ",
      quotes_content:
        "Designing user interfaces that are intuitive and easy to navigate, enhancing user satisfaction and engagement.",
    },
    {
      quotes_heading: "User-Centered Design: ",
      quotes_content:
        "Employing a user-centered design approach to understand user needs, preferences, and behaviors, resulting in interfaces that truly resonate with your target audience.",
    },
  ];

  return (
    <section className={styles.uiux_banner_2}>
      <div className={styles.uiux_bgImage}></div>
      <IT_services_banner_content
        first_line={first_line()}
        second_line={second_line}
        third_line={third_line()}
        card_image={uiux_card_image}
      />
    </section>
  );
};

export default UIUX_collaborate;
