// import web_development from "./OurServicesImages/web_development.png";
import android_development from "./OurServicesImages/android_development.png";
import application_maintanance from "./OurServicesImages/application_maintanance.png";
import website_development from "./OurServicesImages/website_development.png";
import product_development from "./OurServicesImages/product_development.png";
import uiux from "./OurServicesImages/uiuxdesign.png";

export const Our_Services = [
  {
    service_name: "Product Development",
    service_image: product_development,
    about_service:
      "Our teams of experience professionals expers help you create the next generation of software offerings, cost effective solution and with very less time-to-market while ensuring...",
    navigate_to: "/product-development",
  },
  {
    service_name: "Web Development",
    service_image: website_development,
    about_service:
      "Get best website design features by our team of expert web designers for Web based solutions as well as Website development. We offer efficacious and...",
    navigate_to: "/web-development",
  },
  {
    service_name: "UI/UX Development",
    service_image: uiux,
    about_service:
      "Need a website for you business or your personal profession? We will create one for you – the site with elegant design and best in...",
    navigate_to: "/ui_ux-design",
  },
  {
    service_name: "Android Application Development",
    service_image: android_development,
    about_service:
      "Need an App for your business? Our team of expert Andriod and iPhone developers are here to help you expand your business by expanding your...",
    navigate_to: "/android-app-development",
  },
  {
    service_name: "Application Maintanance",
    service_image: application_maintanance,
    about_service:
      "We offer Application Maintenance and support. We will help you maintain all your legacy application so that you can focus on your core business.",
    navigate_to: "/app-maintanance",
  },
];
