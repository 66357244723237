import React from "react";
import Navbar from "../../Home/Header/Navbar";
import Product_header_banner from "../Common/Product_header_banner";
import Payment_Gateway_Header from "./Payment_Gateway_Header";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Payment_gateway_details from "./Payment_gateway_details";
import Payment_gateway_features from "./Payment_gateway_features";

const Payment_Gateway = () => {
  return (
    <>
      <Navbar />
      <Payment_Gateway_Header />
      <Payment_gateway_details />
      <Payment_gateway_features />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default Payment_Gateway;
