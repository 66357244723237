import React from "react";
import pg_image from "./Payment_Gateway_Images/payment_gateway_img.png";
import styles from "./Payment_gateway.module.css";
import Product_Details from "../Common/Product_Details";

const Payment_gateway_details = () => {
  const detail_3 = () => {
    return (
      <>
        Our payment gateway solutions streamline card payments, bank transfers,
        e-wallet transactions, and more, providing a single, integrated platform
        for lightning-fast point-of-sale (POS) and payment processing.
        <p style={{ color: "white" }}>
          Trust Novity to elevate your payment experience and drive business
          growth with our cutting-edge solutions.
        </p>
      </>
    );
  };

  return (
    <section className={styles.pg_details}>
      <div className={styles.pg_details_bgImage}></div>
      <Product_Details
        img_detail={pg_image}
        detail_1={
          "Empower your business with our advanced payment gateway development solutions, offering seamless support for all payment methods and currencies."
        }
        detail_2={
          "With Novity's customizable solutions, you can ensure advanced security while accommodating diverse payment preferences of your customers."
        }
        detail_3={detail_3()}
      />
    </section>
  );
};

export default Payment_gateway_details;
