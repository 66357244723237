import React from "react";
import Navbar from "../../Home/Header/Navbar";
import API_Header from "./API_Header";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";
import API_Details from "./API_Details";
import API_Solution_features from "./API_Solution_features";

const API_solution = () => {
  return (
    <>
      <Navbar />
      <API_Header />
      <API_Details />
      <API_Solution_features />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default API_solution;
