import React from "react";
import web_development from "./Web_Dev_images/web_development.png";
import IT_service_banner from "../Common/IT_service_banner";

const WD_banner = () => {
  return (
    <IT_service_banner
      service_or_product_image={web_development}
      service_or_product_text={"Web"}
      service_or_product_development_text={"Development"}
      service_or_product_tagline={
        "Elevate your online presence with our expert web development solutions."
      }
    />
  );
};

export default WD_banner;
