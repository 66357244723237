import React, { useEffect, useState } from "react";
import styles from "./c_product.module.css";
import "aos/dist/aos.css";
import Aos from "aos";

const Product_Details = ({ img_detail, detail_1, detail_2, detail_3 }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [aosClass, setaosClass] = useState("fade-left");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 768) {
      setaosClass("fade-down");
    }
  }, [screenWidth]);

  useEffect(() => {
    Aos.init({
      duration: 500,
      offset: 10,
      easing: "ease-in-out",
    });
  });

  return (
    <div>
      <div className={styles.details_content}>
        <div className={styles.text_details} data-aos={aosClass}>
          <p className={styles.detail_1}>{detail_1}</p>
          <p className={styles.detail_2}>{detail_2}</p>
          <p className={styles.detail_3}>{detail_3}</p>
        </div>
        <div className={styles.img_detail}>
          <img src={img_detail} alt="broken" data-aos={aosClass} />
        </div>
      </div>
    </div>
  );
};

export default Product_Details;
