import React from "react";
import Navbar from "../../Home/Header/Navbar";
import About_Us_Heading from "./About_Us_Heading";
import Our_vission from "./Our_vission";
import Our_mission from "./Our_mission";
import Our_values from "./Our_values";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import styles from "./about_us.module.css";

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <About_Us_Heading />
      <div className={styles.ov_om}>
        <Our_vission />
        <Our_mission />
      </div>
      <Our_values />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default AboutUs;
