import React from "react";
import Product_header_banner from "../Common/Product_header_banner";
import e_kyc from "./E_KYC_Images/E_KYC.png";

const E_KYC_Header = () => {
  return (
    <>
      <Product_header_banner
        p_image={e_kyc}
        p_service_name={"E-KYC"}
        p_fs={"Solutions"}
        p_tagline={
          "Streamline identity verification with our efficient eKYC solutions."
        }
      />
    </>
  );
};

export default E_KYC_Header;
