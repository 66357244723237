import React from "react";
import Features_section from "../Common/Features_section";

const E_KYC_Features = () => {
  const eKYCSolutionsBenefitsFeatures = {
    title: "Benefits of Our eKYC Solutions",
    description:
      "Discover the advantages of leveraging our eKYC solutions for identity verification.",
    features: [
      {
        name: "Security and Fraud Prevention",
        description:
          "Ensure security and prevent fraud with robust identity verification measures embedded within our eKYC solutions.",
      },
      {
        name: "Real-Time Verification",
        description:
          "Enable real-time identity verification processes for swift and efficient onboarding of customers or users.",
      },
      {
        name: "Improved Customer Experience",
        description:
          "Enhance the customer experience by offering seamless and hassle-free identity verification procedures.",
      },
      {
        name: "Time and Cost Efficiency",
        description:
          "Save time and reduce operational costs by automating the identity verification process with our eKYC solutions.",
      },
      {
        name: "Regulatory Compliance",
        description:
          "Stay compliant with regulatory requirements by implementing eKYC solutions that adhere to industry standards and regulations.",
      },
      {
        name: "Accuracy and Data Integrity",
        description:
          "Ensure accuracy and data integrity in identity verification processes, maintaining trust and credibility with your customers.",
      },
    ],
  };

  return (
    <>
      <Features_section features={eKYCSolutionsBenefitsFeatures} />
    </>
  );
};

export default E_KYC_Features;
