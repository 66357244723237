import React, { useEffect, useState } from "react";
import our_vission from "./About_us_images/our_vision_img.png";
import styles from "./about_us.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Our_vission = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [aosClass, setAosClass] = useState("fade-left");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 768) {
      setAosClass("fade-down");
    }
  }, [screenWidth]);

  useEffect(() => {
    Aos.init({
      duration: 500,
      offset: 100,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <section className={styles.our_vission}>
      <div className={styles.ov_img_content} data-aos={aosClass}>
        <img src={our_vission} alt="" />
      </div>
      <div className={styles.ov_text_content} data-aos={aosClass}>
        <div className={styles.ov_heading}>
          <p>our</p>
          <p>Vission</p>
        </div>
        <div className={styles.ov_description}>
          <p>
            At Novity Technologies LLP, our vision is to revolutionize the way
            businesses leverage technology to drive growth and innovation. We
            aspire to be the leading provider of innovative IT solutions that
            empower businesses to achieve their goals and exceed expectations.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Our_vission;
