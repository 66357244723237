import React from "react";
import styles from "./about_us.module.css";

const Our_values = () => {
  return (
    <section className={styles.our_values}>
      <div className={styles.ovl_heading}>
        <p>Our</p>
        <p>Values</p>
      </div>
      <div className={styles.ovl_grid}>
        <div>
          <p>
            <strong>Innovation</strong>
          </p>
          <p>
            We embrace creativity and innovation to stay ahead of industry
            trends and drive continuous improvement.
          </p>
        </div>
        <div>
          <p>
            <strong>Excellence</strong>
          </p>
          <p>
            We are committed to delivering excellence in everything we do, from
            our solutions to our customer service.
          </p>
        </div>
        <div>
          <p>
            <strong>Integrity</strong>
          </p>
          <p>
            We uphold the highest standards of integrity, honesty, and ethics in
            all our interactions.
          </p>
        </div>
        <div>
          <p>
            <strong>Collaboration</strong>
          </p>
          <p>
            We believe in the power of collaboration and teamwork to achieve
            shared goals and success.
          </p>
        </div>
        <div>
          <p>
            <strong>Customer-Centricity</strong>
          </p>
          <p>
            We prioritize the needs and satisfaction of our clients, going above
            and beyond to exceed their expectations.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Our_values;
