import React from "react";
import styles from "./contact_us.module.css";
import header_image from "./ContactUsImages/contact_us.png";

const Contact_us_header = () => {
  return (
    <header className={styles.cu_page_header}>
      <div className={styles.cu_page_img}>
        <img src={header_image} alt="Contact Us Header" />
      </div>
      <div className={styles.cu_page_text}>
        <p className={styles.cu_text}>Contact Us</p>
        <p className={styles.cu_tagline}>
          Closer to Your Vision, One Contact Away
        </p>
      </div>
    </header>
  );
};

export default Contact_us_header;
