import React from "react";
import pd_image from "./PD_Images/product_development.png";
import styles from "./product_development.module.css";
import IT_service_banner from "../Common/IT_service_banner";
const PD_Heading_banner = () => {
  return (
    <IT_service_banner
      service_or_product_image={pd_image}
      service_or_product_text={"Product"}
      service_or_product_development_text={"Development"}
      service_or_product_tagline={
        " Cultivate groundbreaking ideas into successful products with our expert product development services at Novity"
      }
    />
  );
};

export default PD_Heading_banner;
