import React from "react";
import Product_header_banner from "../Common/Product_header_banner";
import pc_api from "./Payment_Collection_API_Images/payment_collction_api.png";

const Payment_Collecation_API_Header = () => {
  return (
    <>
      <Product_header_banner
        p_image={pc_api}
        p_service_name={"Payment"}
        p_fs={"Collection API"}
        p_tagline={
          "Simplify payment collections with our robust API solutions."
        }
      />
    </>
  );
};

export default Payment_Collecation_API_Header;
