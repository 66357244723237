import React from "react";
import styles from "./api_solution.module.css";
import Product_Details from "../Common/Product_Details";
import img_detail from "./API_SolImages/api_image.png";

const API_Details = () => {
  return (
    
    <section className={styles.api_details}>
      <div className={styles.api_details_bgImage}></div>
      <Product_Details
        img_detail={img_detail}
        detail_1={
          "Empower developers to leverage the functionality of diverse software systems, services or platforms to craft sophisticated applications."
        }
        detail_2={
          "Our API solution service facilitates seamless integration between various systems, enabling efficient data and resource sharing."
        }
        detail_3={
          " Join the realm of modern software development to innovate and create feature-rich applications with ease."
        }
      />
    </section>
  );
};

export default API_Details;
