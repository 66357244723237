import React from "react";
import callus from "./FooterImages/callus.png";
import emailus from "./FooterImages/emailus.png";
import styles from "./footer.module.css";

const Footer_contact = () => {
  return (
    <section className={styles.footer_contact}>
      <div className={styles.callus}>
        <img src={callus} alt="call_us" />
        <p>+91 2717 403287</p>
      </div>
      <div>
        <p>Copyright ©2024 Novity Technologies LLP <br /> All rights reserved </p>
      </div>
      <div className={styles.emailus}>
        <img src={emailus} alt="email_us" />
        <a href="mailto:contact@novitytech.com">contact@novitytech.com</a>
      </div>
    </section>
  );
};

export default Footer_contact;
