import React from "react";
import Navbar from "../../Home/Header/Navbar";
import Payment_Collecation_API_Header from "./Payment_Collecation_API_Header";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Payment_collecation_API_details from "./Payment_collecation_API_details";
import Payment_Collection_API_features from "./Payment_Collection_API_features";

const Payment_Collection_API = () => {
  return (
    <>
      <Navbar />
      <Payment_Collecation_API_Header />
      <Payment_collecation_API_details />
      <Payment_Collection_API_features />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default Payment_Collection_API;
