import React from "react";
import Features_section from "../Common/Features_section";

const Payment_Collection_API_features = () => {
  const paymentCollectionAPI = {
    title: "Payment Collection API Service",
    features: [
      {
        name: "Streamlined Payment Collection",
        description:
          "Simplify payment collection processes for your business with our robust API solution. Seamlessly integrate our API into your existing systems to effortlessly collect payments from your customers.",
      },
      {
        name: "Secure Transactions",
        description:
          "Rest assured with secure transactions facilitated by our payment collection API. With advanced encryption and authentication measures, your customers' payment data remains protected throughout the collection process.",
      },
      {
        name: "Flexible Integration",
        description:
          "Enjoy the flexibility of integrating our payment collection API across various platforms and devices. Whether it's on your website, mobile app, or POS system, our API seamlessly adapts to your business needs.",
      },
      {
        name: "Real-Time Reporting",
        description:
          "Gain valuable insights into your payment collection activities with real-time reporting features provided by our API. Track transaction statuses, monitor payment trends, and optimize your collection strategies for better business outcomes.",
      },
      {
        name: "Customized Solutions",
        description:
          "Tailor our payment collection API to suit your specific business requirements. From branding customization to personalized payment workflows, we offer flexible solutions that align with your unique needs and preferences.",
      },
      {
        name: "Scalable Performance",
        description:
          "Scale your payment collection operations effortlessly as your business grows. Our API is designed to handle high transaction volumes efficiently, ensuring reliable performance even during peak periods.",
      },
      {
        name: "24/7 Support",
        description:
          "Receive round-the-clock support from our team of experts to address any inquiries or issues you may encounter with our payment collection API. We're committed to ensuring a smooth and seamless experience for you and your customers at all times.",
      },
    ],
  };
  return (
    <>
      <Features_section features={paymentCollectionAPI} />
    </>
  );
};

export default Payment_Collection_API_features;
