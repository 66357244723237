import React from "react";
import Features_section from "../Common/Features_section";

const B2B_features = () => {
  const b2bSolutions_features = {
    title: "Top Quality B2B Solutions",
    description:
      "Unlock top-quality B2B solutions designed to streamline your business operations and enhance efficiency across various domains.",
    features: [
      {
        name: "Fast UPI Payment",
        description:
          "Seamlessly integrate UPI payment solutions into your business infrastructure for swift and secure transactions.",
      },
      {
        name: "Payment Gateways",
        description:
          "Enhance payment processing capabilities with robust payment gateway solutions, ensuring seamless transactions for your customers.",
      },
      {
        name: "E-Commerce Solutions",
        description:
          "Elevate your online presence with comprehensive e-commerce solutions tailored to meet your business needs.",
      },
      {
        name: "Mobile Recharges",
        description:
          "Simplify mobile recharge processes for your customers with efficient and user-friendly mobile recharge solutions.",
      },
      {
        name: "Digital Wallets",
        description:
          "Provide your customers with convenient and secure digital wallet solutions for seamless transactions and enhanced financial management.",
      },
      {
        name: "Online Bookings",
        description:
          "Enable hassle-free online bookings for your customers across various sectors, from travel and accommodation to events and services.",
      },
      {
        name: "Financial Management Software",
        description:
          "Optimize your financial processes with advanced financial management software, empowering you to make informed decisions and drive business growth.",
      },
    ],
  };
  return (
    <div>
      <Features_section features={b2bSolutions_features} />
    </div>
  );
};

export default B2B_features;
