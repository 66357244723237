import React from "react";
import IT_service_banner from "../Common/IT_service_banner";
import am_banner from "./AM_images/AM_banner.jpeg";

const AM_Banner = () => {
  return (
    <IT_service_banner
      service_or_product_image={am_banner}
      service_or_product_text={"Android App"}
      service_or_product_development_text={"Maintanance"}
      service_or_product_tagline={
        "Safeguard your Android app's performance with our reliable maintenance solutions"
      }
    />
  );
};

export default AM_Banner;
