import React from "react";
import Features_section from "../Common/Features_section";

const B2C_Features = () => {
  const b2cSolutionsFeatures = {
    title: "B2C Solutions",
    description:
      "Discover our key features designed to enhance the customer experience and streamline business operations:",
    features: [
      {
        name: "Secure Transactions",
        description:
          "Ensure secure transactions for your customers, providing peace of mind and trust in your platform.",
      },
      {
        name: "Customized Notifications",
        description:
          "Deliver personalized notifications to your customers, keeping them informed and engaged throughout their journey.",
      },
      {
        name: "Detailed Payment History",
        description:
          "Empower your customers with access to their detailed payment history, facilitating transparency and accountability.",
      },
      {
        name: "Automated Reminders",
        description:
          "Automate reminders for your customers, ensuring timely notifications and reducing the risk of missed deadlines or payments.",
      },
    ],
  };

  return (
    <>
      <Features_section features={b2cSolutionsFeatures} />
    </>
  );
};

export default B2C_Features;
