import React from "react";
import styles from "./footer.module.css";
import logo from "../Header/NavbarImages/novity-technologies-llp-logo.png";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.contact_details}>
        <div className={styles.logo_container}>
          <img src={logo} alt="Novity_technologies_llp" />
        </div>
        <div>
          <p>
            <strong>Where are we located ?</strong>
          </p>
          <p className={styles.address_container}>
            819, Shivalik Satyamev, <br />
            Nr. Vakil Saheb Bridge,
            <br /> S. P. Ring Road, Bopal, Ahmedabad-380058
          </p>
        </div>
      </div>
      <div className={styles.footer_routes}>
        <div>
          <p className={styles.section_heading}>
            <strong>IT Services</strong>
          </p>
          <div className={styles.section_routes}>
            <a href="#">Product Development</a>
            <a href="#">Web Developemnt</a>
            <a href="#">Android Application Development</a>
            <a href="#">Application Maintanance</a>
            <a href="#">Website Development</a>
          </div>
        </div>
        <div>
          <p className={styles.section_heading}>
            <strong>FinTech Services</strong>
          </p>
          <div className={styles.section_routes}>
            <a href="#">Neo banking</a>
            <a href="#">Money Trasnfer</a>
            <a href="#">Credit Card</a>
            <a href="#">AEPS(Adhaar enebled payment system)</a>
            <a href="#">BBPS(Bharat bill payment system)</a>
            <a href="#">MATM(Micro ATM)</a>
            <a href="#">Loan Repayment</a>
            <a href="#">Insurance</a>
            <a href="#">CMS</a>
          </div>
        </div>
        <div>
          <p className={styles.section_heading}>
            <strong>Products</strong>
          </p>
          <div className={styles.section_routes}>
            <a href="#">B2B Solutions</a>
            <a href="#">B2C Solutions</a>
            <a href="#">API Solutions</a>
            <a href="#">Travel Services</a>
            <a href="#">E-Commerce</a>
            <a href="#">Government Services</a>
            <a href="#">E-KYC Solution</a>
            <a href="#">Payment Gateway</a>
            <a href="#">Payment Collection API</a>
          </div>
        </div>
        <div>
          <p className={styles.section_heading}>
            <strong>Company</strong>
          </p>
          <div className={styles.section_routes}>
            <a href="#">About Us</a>
            <a href="#">Career</a>
            <a href="#">Testimonials</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
