import React from "react";
import styles from "./c_product.module.css";

const Features_section = ({ features }) => {
  return (
    <div className={styles.features_section}>
      <div className={styles.feature_heading}>
        <p className={styles.fs_heading}>{features.title}</p>
        <hr />
        <p className={styles.fs_tagline}>{features.description}</p>
      </div>
      <div className={styles.container}>
        <div className={styles.feature_grid}>
          {features.features.map((el, index) => {
            return (
              <div className={styles.feature_card}>
                <p className={styles.heading}>
                  <strong>{el.name}</strong>
                </p>
                <p className={styles.content}>{el.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Features_section;
