import React from "react";
import styles from "./e_commerce.module.css";
import e_commerce from "./E-Commerce_Images/e-commerce.png";
import Product_Details from "../Common/Product_Details";

const E_commerce_details = () => {
  return (
    <section className={styles.e_commerce_details}>
      <div className={styles.e_commerce_details_bgImage}></div>
      <Product_Details
        img_detail={e_commerce}
        detail_1={
          "Experience seamless transactions and elevate your customer's shopping journey with our e-commerce payment development solutions. "
        }
        detail_2={
          " Tailored with your customers in mind, our expert offerings aim to enhance their experience while accelerating your business growth. Improve customer satisfaction, drive sales and stay ahead in the competitive e-commerce landscape. "
        }
        detail_3={
          "Join us in innovation, where your business profits soar and your brand value flourishes. With our comprehensive e-commerce payment development solutions, you'll always be moving forward."
        }
      />
    </section>
  );
};

export default E_commerce_details;
