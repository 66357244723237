import React from "react";
import Navbar from "../../Home/Header/Navbar";
import WD_banner from "./WD_banner";
import WD_collaborate from "./WD_collaborate";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";

const Web_Development = () => {
  return (
    <>
      <Navbar />
      <WD_banner />
      <WD_collaborate />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default Web_Development;
