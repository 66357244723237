import React from "react";
import Product_header_banner from "../Common/Product_header_banner";
import b2b from "./B2B_Images/B2B_header_banner.png";

const B2B_Header = () => {
  const tagline = () => {
    return (
      <>
        Elevate your business with our global <br />
        B2B software and fintech solutions.
      </>
    );
  };

  return (
    <>
      <Product_header_banner
        p_image={b2b}
        p_service_name={"B2B Software"}
        p_and={"&"}
        p_fs={"FinTech Solutions"}
        p_tagline={tagline()}
      />
    </>
  );
};

export default B2B_Header;
