import React from "react";
import styles from "./aad.module.css";
import IT_services_banner_content from "../Common/IT_services_banner_content";
import ad_card_image from "./AADImages/ad_card_image.png";

const AAD_collaborate = () => {
  const first_line = () => {
    return (
      <>
        Experience the potential of your digital footprint with{" "}
        <strong>Novity's </strong>
        Android application development services.
      </>
    );
  };
  const second_line =
    "Our skilled developers bring years of expertise to the table, ensuring your app is not just functional, but dynamic and engaging.";
  const third_line = () => {
    return (
      <>
        Whether you're a budding startup or an established enterprise, our
        tailored solutions are crafted to drive user engagement, boost
        downloads, and propel your business to new heights with Novity.
        <br />
        <p style={{ color: "white" }}>
          your Android app isn't just a product—it's a powerful tool for growth
          and success
        </p>
      </>
    );
  };

  const quotes_grid = [
    {
      quotes_heading: "User-Centric Design: ",
      quotes_content:
        "Crafting Android apps with intuitive interfaces and smooth navigation to delight users.",
    },
    {
      quotes_heading: "Latest Technology: ",
      quotes_content:
        " Utilizing cutting-edge Android development tools for high-performance, feature-rich apps.",
    },
    {
      quotes_heading: "Agile Approach: ",
      quotes_content:
        " Embracing agile methodologies for rapid adaptation, frequent updates, and on-time delivery.",
    },
  ];

  return (
    <section className={styles.aad_banner_2}>
      <div className={styles.add_bgImage}></div>
      <div className={styles.add_banner_content}>
        <IT_services_banner_content
          first_line={first_line()}
          second_line={second_line}
          third_line={third_line()}
          card_image={ad_card_image}
        />
      </div>
    </section>
  );
};

export default AAD_collaborate;
