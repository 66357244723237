import React from "react";
import Navbar from "../../Home/Header/Navbar";
import B2B_Header from "./B2B_Header";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import B2B_Solution_Details from "./B2B_Solution_Details";
import Features_section from "../Common/Features_section";
import B2B_features from "./B2B_features";

const B2B_Solution = () => {
  return (
    <>
      <Navbar />
      <B2B_Header />
      <B2B_Solution_Details />
      <B2B_features />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default B2B_Solution;
