import React from "react";
import styles from "./contact_us.module.css";
import { Button, Input, Select, Textarea } from "@chakra-ui/react";

const Contact_us_details = () => {
  return (
    <section className={styles.cu_page_details}>
      <div className={styles.cu_details_bg}></div>
      <div className={styles.cu_details_content}>
        <div className={styles.cu_details_textContent}>
          <p className={styles.cu_hpm}>
            <strong>Have a Project in Mind ?</strong>
          </p>
          <div className={styles.cu_description}>
            <p>
              You’re only a step away. <br />
              Get a competitive proposal & FREE consultation from our industry
              experts upon submitting your details.
            </p>
            <p className={styles.secondDis}>
              Fill out the form here with your project details and contact
              information, and our team will get back to you promptly
            </p>
          </div>
        </div>
        <div className={styles.cu_details_formContent}>
          <div>
            <p className={styles.cu_rts}>Ready to start?</p>
          </div>
          <div style={{ display: "flex" }}>
            <Input type="text" placeholder="Full Name" />
            <Input type="email" placeholder="E-mail" ml={10} />
          </div>
          <Input type="number" placeholder="Mobile Number" mt={6} />
          <Select placeholder="Select Servies" color={"grey"} mt={6}>
            <option>IT Service</option>
            <option>FinTech Services</option>
            <option>Product Development</option>
          </Select>
          <Textarea placeholder="Type your message here..." mt={6} />
          <Input
            type="file"
            color={"grey"}
            mt={6}
            display={"flex"}
            alignItems={"center"}
          />
          <Button colorScheme="twitter" mt={10}>
            Send Request
          </Button>
          <p className={styles.afterSend}>
            Our dedicated team is here to assist you and provide the information
            you need.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact_us_details;
