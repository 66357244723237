import React from "react";
import Navbar from "../../Home/Header/Navbar";
import AAD_Banner from "./AAD_Banner";
import AAD_collaborate from "./AAD_collaborate";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";

const Android_App_Development = () => {
  return (
    <div>
      <Navbar />
      <AAD_Banner />
      <AAD_collaborate />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </div>
  );
};

export default Android_App_Development;
