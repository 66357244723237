import React from "react";
import Navbar from "../../Home/Header/Navbar";
import { Heading } from "@chakra-ui/react";
import AM_Banner from "./AM_Banner";
import AM_collaborate from "./AM_collaborate";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";

const App_Maintanance = () => {
  return (
    <>
      <Navbar />
      <AM_Banner />
      <AM_collaborate />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default App_Maintanance;
