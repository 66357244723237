import React from "react";
import Navbar from "../../Home/Header/Navbar";
import B2C_Header from "./B2C_Header";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import B2C_solution_details from "./B2C_solution_details";
import B2C_Features from "./B2C_Features";

const B2C_Solution = () => {
  return (
    <>
      <Navbar />
      <B2C_Header />
      <B2C_solution_details />
      <B2C_Features />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default B2C_Solution;
