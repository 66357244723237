import React, { useEffect } from "react";
import styles from "./WhySu.module.css";
import innovation from "./WhyUsImages/innovation.png";
import expertice from "./WhyUsImages/technology_expertise.png";
import long_term_relation from "./WhyUsImages/long_term_relation.png";
import quality_assurance from "./WhyUsImages/quality_assurance.png";
import experianced_team from "./WhyUsImages/experianced_team.png";
import support from "./WhyUsImages/support.png";
import onsite_delivery from "./WhyUsImages/onsite_delivery.png";
import AOS from "aos";
import "aos/dist/aos.css";

const WhyUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      offset: 200,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <section data-aos="zoom-in-down" className={styles.whyus_section}>
      <div className={styles.whyus_heading}>
        <p>Why</p>
        <p>Novity ? </p>
      </div>
      <div className={styles.whyus_cards}>
        <div className={styles.whyus_card}>
          <div className={styles.whyus_img}>
            <img src={innovation} alt="" />
          </div>
          <p className={styles.content}>
            Innovative approach to provide Business Solutions
          </p>
        </div>
        <div className={styles.whyus_card}>
          {/* <div className={styles.whyus_img}> */}
          <img src={experianced_team} alt="" />
          {/* </div> */}
          <p className={styles.content}>
            Team of experts having 10+ Years of IT Experience
          </p>
        </div>
        <div className={styles.whyus_card}>
          {/* <div className={styles.whyus_img}> */}
          <img src={quality_assurance} alt="" />
          {/* </div> */}
          <p className={styles.content}>Quality Assurance</p>
        </div>
        <div className={styles.whyus_card}>
          {/* <div className={styles.whyus_img}> */}
          <img src={expertice} alt="" />
          {/* </div> */}
          <p className={styles.content}>
            Expertise on diverse technologies & domains
          </p>
        </div>
        <div className={styles.whyus_card}>
          <div className={styles.whyus_img}>
            <img src={long_term_relation} alt="" />
          </div>
          <p className={styles.content}>
            Believe in building long term relationship
          </p>
        </div>
        <div className={styles.whyus_card}>
          <div className={styles.whyus_img}>
            <img src={support} alt="" />
          </div>
          <p className={styles.content}>Support Assurance </p>
        </div>
        <div className={styles.whyus_card}>
          <div className={styles.whyus_img}>
            <img src={onsite_delivery} alt="" />
          </div>
          <p className={styles.content}>Onsite-offshore Delivery Model </p>
        </div>
        <div
          className={styles.whyus_card}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <p style={{ textAlign: "center" }}>And</p>
            <p style={{ textAlign: "center" }}>Many</p>
            <p style={{ textAlign: "center" }}>More...</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
