import React from "react";
import Product_header_banner from "../Common/Product_header_banner";
import b2c from "./B2C_Images/B2C.png";

const B2C_Header = () => {
  const tagline = () => {
    return (
      <>
        Transform consumer experiences with our <br /> innovative B2C solutions.
      </>
    );
  };

  return (
    <>
      <Product_header_banner
        p_image={b2c}
        p_service_name={"B2C Software"}
        p_and={"&"}
        p_fs={"FinTech Services"}
        p_tagline={tagline()}
      />
    </>
  );
};

export default B2C_Header;
