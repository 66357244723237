import React from "react";
import styles from "./wd.module.css";
import IT_services_banner_content from "../Common/IT_services_banner_content";
import wd_card_image from "./Web_Dev_images/web_development_card_image.png";

const WD_collaborate = () => {
  const first_line = () => {
    return (
      <>
        Unlock the power of online presence with our results-driven website
        development services at <strong>Novity</strong>.
      </>
    );
  };
  const second_line =
    "Our seasoned developers harness years of experience to craft dynamic, user-friendly, and engaging websites tailored to elevate your brand.";

  const third_line = () => {
    return (
      <>
        From startups to enterprises, we deliver quality development solutions
        designed to attract more clients, drive sales, and foster exponential
        growth for your business.
      </>
    );
  };

  return (
    <section className={styles.wd_banner_2}>
      <div className={styles.wd_bgImage}></div>
      <IT_services_banner_content
        first_line={first_line()}
        second_line={second_line}
        third_line={third_line()}
        card_image={wd_card_image}
      />
    </section>
  );
};

export default WD_collaborate;
