import React from "react";
import Features_section from "../Common/Features_section";

const E_Commerce_features = () => {
  const ecommerceDevelopmentFeature = {
    title: "E-commerce Website and App Development",
    description:
      "Discover our comprehensive e-commerce solutions designed to elevate your online business presence:",
    features: [
      {
        name: "Payment Gateway Integration",
        description:
          "Seamlessly integrate payment gateways to facilitate secure and convenient online transactions for your customers.",
      },
      {
        name: "Fraud Detection and Prevention",
        description:
          "Implement advanced fraud detection mechanisms to safeguard your e-commerce platform and protect against fraudulent activities.",
      },
      {
        name: "Digital Wallets",
        description:
          "Offer digital wallet options to your customers, allowing for quick and easy payments while enhancing user convenience and flexibility.",
      },
      {
        name: "Secure Transactions",
        description:
          "Ensure secure transactions for your customers, providing peace of mind and trust in your platform.",
      },
    ],
  };
  return (
    <>
      <Features_section features={ecommerceDevelopmentFeature} />
    </>
  );
};

export default E_Commerce_features;
