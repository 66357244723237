import React, { useEffect } from "react";
import c_styles from "./common.module.css";
import "aos/dist/aos.css";
import Aos from "aos";

const IT_service_banner = ({
  service_or_product_image,
  service_or_product_text,
  service_or_product_development_text,
  service_or_product_tagline,
}) => {

  useEffect(() => {
    setTimeout(() => {
      Aos.init({
        duration: 500,
        offset: 10,
        easing: "ease-in-out",
      });
    }, 500);
  }, []);

  return (
    <div className={c_styles.container}>
      <section className={c_styles.it_banner}>
        <div className={c_styles.it_image_box} data-aos="fade-left">
          <img src={service_or_product_image} alt="broken" />
        </div>
        <div className={c_styles.it_banner_content_box} data-aos="fade-right">
          <div style={{ width: "70%", margin: "auto" }}>
            <p className={c_styles.it_text}>
              <strong>{service_or_product_text}</strong>
            </p>
            <p className={c_styles.it_development_text}>
              {service_or_product_development_text}
            </p>
            <p className={c_styles.it_tagline}>{service_or_product_tagline}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IT_service_banner;
