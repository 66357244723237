import React, { useEffect, useState } from "react";
import styles from "./about_us.module.css";
import our_mission from "./About_us_images/our_mission.png";
import "aos/dist/aos.css";
import Aos from "aos";

const Our_mission = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [aosClass, setAosClass] = useState("fade-right");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 768) {
      setAosClass("fade-down");
    }
  }, [screenWidth]);

  useEffect(() => {
    Aos.init({
      duration: 500,
      offset: 100,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <section className={styles.our_mission}>
      <div className={styles.om_text_content} data-aos={aosClass}>
        <div className={styles.om_heading}>
          <p>our</p>
          <p>Mission</p>
        </div>
        <div className={styles.om_description}>
          <p>
            Our mission is to deliver exceptional value to our clients by
            providing tailor-made technology solutions that address their unique
            challenges and drive tangible results. We strive to foster long-term
            partnerships built on trust, transparency, and mutual success.
          </p>
        </div>
      </div>
      <div className={styles.om_img_content} data-aos={aosClass}>
        <img src={our_mission} alt="" />
      </div>
    </section>
  );
};

export default Our_mission;
