import React from "react";
import styles from "./bannerSaction.module.css";
import { Heading } from "@chakra-ui/react";
import gif from "./BannerImages/Web Development.gif";

const BannerSection = () => {
  return (
    <section className={styles.bannerSection}>
      <div className={styles.container}>
        <div className={styles.textContent}>
          <p className={styles.wlTextContent}>Welcome to</p>
          <p className={styles.tagLine}>
            {" "}
            People-centric and Innovation-driven
          </p>
          <Heading
            as="h1"
            size="xl"
            color="#00539C"
            className={styles.heading1}
          >
            {`<>Novity Technoloogies</>`}
          </Heading>
          {/* <Heading as="h1" size="xl" color="#00539C" className="type">
           {`< Novity Technoloogies />`}
              </Heading> */}
          <p>
            Dedicated to redefining benchmarks in software product development.
            Our commitment to care, value, and nurture our people fuels our
            journey to provide customers with cost-effective, high-quality
            business solutions.
          </p>
        </div>
        <div className={styles.imgContent}>
          <img src={gif} alt="" />
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
{
  /* <div style={{border:"1px solid red"}}>
        <img src={bgImage} alt="" width={"100%"}/>
      </div>
      <div className={styles.content}>
        <div className={styles.tagline}>
          <p>
            People-centric <br /> and <br />
            Innovation-driven
          </p>
        </div>
        <div className={styles.aftertagline}>
          <p>
            <strong>Novity Technologies </strong>
            is dedicated to redefining benchmarks in software product
            development. Our commitment to care, value, and nurture our people
            fuels our journey to provide customers with cost-effective,
            high-quality business solutions.
          </p>
        </div>
      </div> */
}
