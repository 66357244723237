import React from "react";
import Navbar from "../Home/Header/Navbar";
import Contact_us_header from "./Contact_us_header";
import Contact_us_details from "./Contact_us_details";
import Footer from "../Home/Footer/Footer";
import Footer_contact from "../Home/Footer/Footer_contact";

const Contact_Us = () => {
  return (
    <>
      <Navbar />
      <Contact_us_header />
      <Contact_us_details />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default Contact_Us;
