import React from "react";
// import b2b from "../B2B_Solution/B2B_Images/B2B_header_banner.png";
import styles from "./c_product.module.css";

const Product_header_banner = ({
  p_image,
  p_service_name,
  p_and,
  p_fs,
  p_tagline,
}) => {
  return (
    <div className={styles.container}>
      <section className={styles.product_banner}>
        <div className={styles.p_img_box}>
          <img src={p_image} alt="header_banner" />
        </div>
        <div className={styles.p_textContent_box}>
          <div className={styles.p_heading}>
            <p className={styles.p_heading1}>{p_service_name}</p>
            <p className={styles.and}>
              <span>{p_and}</span>
            </p>
            <p className={styles.p_heading2}>{p_fs}</p>
          </div>
          <p className={styles.p_tagline}>{p_tagline}</p>
        </div>
      </section>
    </div>
  );
};

export default Product_header_banner;
