import React from "react";
import Features_section from "../Common/Features_section";

const Travel_features = () => {
  const travel_booking_service = {
    title: "Our In-Demand Travel Bookings",
    description:
      "Below you will explore some of our most in-demand, affordable, and secure travel booking options.",
    features: [
      {
        name: "Train Tickets",
        description:
          "With our apps and software, you can offer smooth train ticket booking to your customers. We can create a customized booking solution that meets your requirements. Now attract more travelers and commuters.",
      },
      {
        name: "Flight Bookings",
        description:
          "Our skilled experts can develop customized solutions for domestic and international flight bookings for you. Just mention your exact requirements and get the perfect Omni Channel booking dashboard.",
      },
      {
        name: "Hotel Accommodation",
        description:
          "With our commission-free booking system, you can attract more customers. We create eye-catching and user-friendly systems that include upsells and add-ons during the booking process. Connect with our team now.",
      },
      {
        name: "IRCTC Integration",
        description:
          "We help various ticket booking agencies to integrate with the IRCTC system. You can easily offer the bookings as offered by IRCTC. It's time to be part of a seamless booking system and improve revenue.",
      },
    ],
  };

  return (
    <>
      <Features_section features={travel_booking_service} />
    </>
  );
};

export default Travel_features;
