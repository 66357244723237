import React from "react";
import Navbar from "../../Home/Header/Navbar";
import PD_Heading_banner from "./PD_Heading_banner";
import Pd_Collaborate from "./Pd_Collaborate";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";

const Product_Development = () => {
  return (
    <>
      <Navbar />
      <PD_Heading_banner />
      <Pd_Collaborate />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default Product_Development;
