import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./Components/Home/HomePage";
import { routes } from "./routes";
import ScrollToTopButton from "./Components/ScrollToTop";
import { useEffect } from "react";
import Contact_Us from "./Components/ContactUs/Contact_Us";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const getRoutes = (allroutes) => {
    return allroutes.map((route) => {
      if (route.route) {
        return (
          <Route
            key={route.name}
            path={route.route}
            element={route.component}
          />
        );
      }
      if (route.submenu) {
        return route.submenu.map((subRoute) => (
          <Route
            key={subRoute.name}
            path={subRoute.route}
            element={subRoute.component}
          />
        ));
      } else {
        return (
          <Route
            key={route.name}
            path={route.route}
            element={route.component}
          />
        );
      }
    });
  };

  return (
    <>
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to={"/home1"} />} />
        <Route path="/home1" element={<HomePage />}></Route>
        <Route path="/contact-us" element={<Contact_Us />}></Route>
      </Routes>
      <ScrollToTopButton />
    </>
  );
}

export default App;
