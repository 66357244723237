import React from "react";
import styles from "./contact_us.module.css";
import contact_us_bannerImage from "./ContactUsImages/contact_us_banner.png";
import { Button } from "@chakra-ui/react";
import { ArrowRightIcon } from "@chakra-ui/icons";
const Contact_Us_banner = () => {
  return (
    <section className={styles.contact_us_banner}>
      <div className={styles.text_content}>
        <div>
          <p className={styles.ready}>
            Ready to elevate your digital presence?{" "}
          </p>
          <p className={styles.contact}>
            Contact us today and let's turn your vision into reality.
          </p>
        </div>
        <div className={styles.button_content}>
          <Button rightIcon={<ArrowRightIcon />} colorScheme="twitter">
            Get Started
          </Button>
        </div>
      </div>
      <div className={styles.img_content}>
        <img src={contact_us_bannerImage} alt="Contact US banner" />
      </div>
    </section>
  );
};

export default Contact_Us_banner;
