import React from "react";
import Navbar from "../../Home/Header/Navbar";
import Travel_Services_Header from "./Travel_Services_Header";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Travel_services_details from "./Travel_services_details";
import Travel_features from "./Travel_features";

const Travel_Services = () => {
  return (
    <>
      <Navbar />
      <Travel_Services_Header />
      <Travel_services_details />
      <Travel_features />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default Travel_Services;
