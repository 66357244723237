import React from "react";
import styles from "./about_us.module.css";
import about_us from "./About_us_images/About_us.png";

const About_Us_Heading = () => {
  return (
    <header className={styles.about_us_header}>
      <div className={styles.text_Content}>
        <p>
          <strong className={styles.greetings}>
            Welcome to <br />
            <span style={{ color: "#54b3d6" }}>Novity Technologies LLP</span>
            <br />
          </strong>
        </p>
        <p className={styles.tagline}>
          where innovation meets excellence in technology solutions.
        </p>
      </div>
      <div className={styles.img_content}>
        <img src={about_us} alt="about us image" />
      </div>
    </header>
  );
};

export default About_Us_Heading;
