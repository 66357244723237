import React from "react";
import Features_section from "../Common/Features_section";

const API_Solution_features = () => {
  const apiSolutionsBenefits = {
    title: "Benefits of Our API Solutions",
    description:
      "Discover the advantages of leveraging our API solutions for your business:",
    features: [
      {
        name: "Innovation and Collaboration",
        description:
          "Drive innovation and foster collaboration by providing developers with access to your platform's functionality and data through APIs.",
      },
      {
        name: "Seamless Integration",
        description:
          "Achieve seamless integration between different systems and applications, enabling efficient data exchange and workflow automation.",
      },
      {
        name: "Reuse and Efficiency",
        description:
          "Maximize efficiency and reduce development time by reusing existing API components across multiple projects and applications.",
      },
      {
        name: "Efficient Expansion",
        description:
          "Facilitate rapid business expansion by offering APIs that enable easy scaling and integration with new technologies and services.",
      },
      {
        name: "Cross-Platform Compatibility",
        description:
          "Ensure compatibility across various platforms and devices, allowing for broader reach and enhanced user experience.",
      },
    ],
  };
  return (
    <>
      <Features_section features={apiSolutionsBenefits} />
    </>
  );
};

export default API_Solution_features;
