import React, { useEffect, useState } from "react";
import c_styles from "./common.module.css";
import "aos/dist/aos.css";
import Aos from "aos";

const IT_services_banner_content = ({
  first_line,
  second_line,
  third_line,
  card_image,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [services_aosClass, set_services_aosClass] = useState("fade-left");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 768) {
      set_services_aosClass("fade-down");
    }
  }, [screenWidth]);

  useEffect(() => {
    // setTimeout(() => {
    Aos.init({
      duration: 500,
      offset: 10,
      easing: "ease-in-out",
    });
    // }, 500);
  }, []);
  return (
    <div className={c_styles.container}>

    <div className={c_styles.banner_content}>
      <div className={c_styles.text_content} data-aos={services_aosClass}>
        <p className={c_styles.first_line}>{first_line}</p>
        <p className={c_styles.second_line}>{second_line}</p>
        <p className={c_styles.third_line}>{third_line}</p>
      </div>
      <div className={c_styles.img_content}>
        <img src={card_image} alt="" data-aos={services_aosClass} />
      </div>
    </div>
    </div>
  );
};

export default IT_services_banner_content;
