import React from "react";
import styles from "./wom.module.css";
import { Button } from "@chakra-ui/react";
import womImage from "./WOMImages/wom.png";
import { useNavigate } from "react-router-dom";

const WhatsOnYourMind = () => {
  const navigate = useNavigate();
  return (
    <section className={styles.womSection}>
      <div style={{ display: "flex" }}>
        <div className={styles.context}>
          <img src={womImage} alt="" />
          <div className={styles.content}>
            <div className={styles.questions}>
              <p>
                <strong>What's on your Mind today?</strong>
              </p>
              <ul>
                <li>Have project in mind ?</li>
                <li>Want a solution for your bussiness ?</li>
                <li>
                  Thinking about optimizing your workflow with the latest tools?
                </li>
              </ul>
            </div>
            <div className={styles.letsStart}>
              <p>
                Your vision, our code – <br />
                together, let's create a strong foundation for success.
              </p>
              <Button zIndex={9999} onClick={() => navigate("/contact-us")}>
                Let's Discuss{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.area}>
        <ul className={styles.circles}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </section>
  );
};

export default WhatsOnYourMind;
