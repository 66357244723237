import React from "react";
import styles from "./e_kyc.module.css";
import e_kyc from "./E_KYC_Images/ekyc_img.png";
import Product_Details from "../Common/Product_Details";

const E_KYC_details = () => {
  return (
    <section className={styles.e_kyc_details}>
      <div className={styles.e_kyc_details_bgImage}></div>
      <Product_Details
        img_detail={e_kyc}
        detail_1={
          "Empower developers to leverage the functionality of diverse software systems, services or platforms to craft sophisticated applications."
        }
        detail_2={
          "Our API solution service facilitates seamless integration between various systems, enabling efficient data and resource sharing."
        }
        detail_3={
          " Join the realm of modern software development to innovate and createfeature-rich applications with ease."
        }
      />
    </section>
  );
};

export default E_KYC_details;
