import React from "react";
import Navbar from "../../Home/Header/Navbar";
import UIUX_banner from "./UIUX_banner";
import IT_services_banner_content from "../Common/IT_services_banner_content";
import UIUX_collaborate from "./UIUX_collaborate";
import Footer from "../../Home/Footer/Footer";
import Footer_contact from "../../Home/Footer/Footer_contact";
import Contact_Us_banner from "../../ContactUs/Contact_Us_banner";

const UIUX_Design = () => {
  return (
    <>
      <Navbar />
      <UIUX_banner />
      <UIUX_collaborate />
      <Contact_Us_banner />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default UIUX_Design;
