import React from "react";
import styles from "./product_development.module.css";
import IT_services_banner_content from "../Common/IT_services_banner_content";
import pd_card_image from "./PD_Images/pd_card_image.png";

const Pd_Collaborate = () => {
  const first_line = () => {
    return (
      <>
        Embark on a transformative collaboration with our skilled developers at{" "}
        <strong>Novity.</strong>
      </>
    );
  };
  const second_line =
    "Experience the seamless fusion of innovative thinking, technical prowess, and strategic execution as we breathe life into your ideas through exceptional product development services.";

  const third_line = () => {
    return (
      <>
        Your journey from concept to a successful product begins here – let's
        create something extraordinary together.
      </>
    );
  };

  return (
    <section className={styles.pd_banner_2}>
      <div className={styles.pd_bgImage}></div>
      <IT_services_banner_content
        first_line={first_line()}
        second_line={second_line}
        third_line={third_line()}
        card_image={pd_card_image}
      />
    </section>
  );
};

export default Pd_Collaborate;
// Dive into a collaborative journey
// where we bring your concepts to life through expert product
// development solutions. Our specialized team comprehensively grasps the
// intricate blend of innovative thinking, technical expertise, and
// strategic planning required to transform your vision into a
// successful, market-ready product. Trust us to be your catalyst in the
// journey from idea to impactful reality.
