import React, { useEffect, useState } from "react";
import styles from "./ourProductSection.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { our_product } from "./our_product";
import our_products_bg from "./OurImages/our_product.png";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";

const OurProductSection = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [services_bg_aosClass, setservices_bg_aosClass] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate()

  const handleNavigate = (pathname)=>{
    navigate(pathname)
  }

  useEffect(() => {
    if (screenWidth < 768) {
      setservices_bg_aosClass("fade-up");
    } else {
      setservices_bg_aosClass("fade-left");
    }
  }, [screenWidth]);

  useEffect(() => {
    Aos.init({
      duration: 500,
      offset: 10,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <section id="our_products" className={styles.our_products}>
      <div className={styles.opHeading}>
        {/* <div data-aos={services_bg_aosClass}>
          <img src={our_products_bg} alt="" />
        </div> */}
        <div data-aos="fade-down" className={styles.opHeadingContent}>
          <p>Our</p>
          <p>Products</p>
          <p className={styles.products_tagline}>Your Vision, Our Expertise</p>
        </div>
      </div>
      <div className={styles.product} data-aos={services_bg_aosClass}>
        {our_product.map((product, index) => {
          return (
            <div className={styles.product__box} key={index}>
              {/* <figure className={styles.product__icon}>
              </figure> */}
              <img src={product.product_image} alt="" />
              <div
              // className={styles.product__content}
              >
                <h2 className={styles.product__title}>
                  {product.product_name}
                </h2>
                <Button
                  colorScheme="twitter"
                  size={"sm"}
                  onClick={() => handleNavigate(product.navigate_to)}
                >
                  Explore More
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default OurProductSection;

{
  /* 
  <div className={styles.product_cards}>
      {our_product.map((product, pro_index) => {
          return (
            <div className={styles.card} key={pro_index}>
              <div className={styles.product_image}>
                <img src={product.product_image} alt={product.product_name} />
              </div>
              <p className={styles.product_name}>{product.product_name}</p>
              <p className={styles.about_product}>{product.about_product}</p>
              {product.explore_more_button}
            </div>
          );
      })}
  </div>
 */
}
