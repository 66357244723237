import React from "react";
import Product_header_banner from "../Common/Product_header_banner";
import e_commerce from "./E-Commerce_Images/E_commerce.png";

function E_Commerce_Header() {
  return (
    <>
      <Product_header_banner
        p_image={e_commerce}
        p_service_name={"E-Commerce"}
        p_fs={"Payment Development"}
        p_tagline={
          "Simplify online transactions with our tailored ecommerce payment solutions"
        }
      />
    </>
  );
}

export default E_Commerce_Header;
