import React, { useEffect, useState } from "react";
import styles from "./ourServiceSection.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { Our_Services } from "./our_services";
import services_bg from "./OurServicesImages/services_bg.png";
import { Button } from "@chakra-ui/react";

const OurServicesSection = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [services_bg_aosClass, setservices_bg_aosClass] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 768) {
      setservices_bg_aosClass("fade-up");
    } else {
      setservices_bg_aosClass("fade-left");
    }
  }, [screenWidth]);

  useEffect(() => {
    Aos.init({
      duration: 500,
      offset: 10,
      easing: "ease-in-out",
      // once: true,
    });
  }, []);

  const navigate = useNavigate();
  const handleNavigate = (pathname) => {
    navigate(pathname);
  };

  return (
    <section id="our_services" className={styles.our_services}>
      <div className={styles.osHeading}>
        <div data-aos="fade-down" className={styles.osHeadingContent}>
          <p>Our </p>
          <p>IT Services</p>
          <p className={styles.service_tagline}>
            Empower your digital journey with our comprehensive range of
            services at Novity.
          </p>
        </div>
        {/* <div data-aos={services_bg_aosClass}>
          <img src={services_bg} alt="" />
        </div> */}
      </div>
      <div className={styles.services} data-aos="fade-rig">
        {Our_Services.map((services, index) => {
          return (
            <div className={styles.services__box} key={index}>
              {/* <figure
                className={styles.services__icon}
                // style={{ "--i": "#4fa3f8f" }}
              >
              </figure> */}
              <img src={services.service_image} alt="" />
              <div
              //className={styles.services__content}
              >
                <p className={styles.services__title}>
                  {services.service_name}
                </p>
                <Button
                  colorScheme="twitter"
                  size={"sm"}
                  onClick={() => handleNavigate(services.navigate_to)}
                >
                  Explore More
                </Button>
              </div>
            </div>
          );
        })}
        {/* <script
          type="module"
          src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
        ></script>
        <script
          nomodule
          src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"
        ></script> */}
      </div>

      {/* <div className={styles.services_cards}>
        {Our_Services.map((service, key) => {
          return (
            <div className={styles.card} key={key}>
              <div className={styles.service_image}>
                <img src={service.service_image} alt={service.service_name} />
              </div>
              <p className={styles.service_name}>{service.service_name}</p>
              <p className={styles.about_service}>{service.about_service}</p>
              {service.explore_service_button}
            </div>
          );
        })}
      </div> */}
    </section>
  );
};

export default OurServicesSection;
