// import { Button } from "@chakra-ui/react";
// import mobileAndDTH from "./OurImages/mobile&DTH Reacharge app.png";
// import mobileRecharge from "./OurImages/mobile_recharge.png";
// import schoolErP from "./OurImages/School ERP.png";
import b2b from "./OurImages/b2b.png";
import b2c from "./OurImages/b2c.png";
import api_solutions from "./OurImages/api_solution.png";
import travel_services from "./OurImages/travel.png";
import e_commerce from "./OurImages/e-commerce.png";
// import govt_services from "./OurImages/govt-services.png";
import e_kyc from "./OurImages/e-kyc.png";
import payment_gateway from "./OurImages/payment_gateway.png";
import payment_collcation from "./OurImages/payement_collaction_api.png";

export const our_product = [
  {
    product_name: "B2B Solution",
    product_image: b2b,
    about_product:
      "Mobile and DTH Recharge App for Distributor, Channel Partners and Retailers",
    navigate_to: "/b2b",
  },
  {
    product_name: "B2C Solution",
    product_image: b2c,
    about_product:
      "Mobile and DTH Recharge App for Distributor, Channel Partners and Retailers",
    navigate_to: "/b2c",
  },
  {
    product_name: "API Solution",
    product_image: api_solutions,
    about_product: "",
    navigate_to: "/api-solutions",
  },
  {
    product_name: "Travel Services",
    product_image: travel_services,
    about_product: "",
    navigate_to: "/travel-services",
  },
  {
    product_name: "E-Commerce",
    product_image: e_commerce,
    about_product: "",
    navigate_to: "/e-commerce",
  },
  // {
  //   product_name: "Govt-Services",
  //   product_image: govt_services,
  //   about_product: "",
  // },
  {
    product_name: "E-Kyc",
    product_image: e_kyc,
    about_product: "",
    navigate_to: "/e-kyc-solution",
  },
  {
    product_name: "Payment Gateway",
    product_image: payment_gateway,
    about_product: "",
    navigate_to: "/payment-gateway",
  },
  {
    product_name: "Payment Colletion API",
    product_image: payment_collcation,
    about_product: "",
    navigate_to: "/payment-colletion-api",
  },
];

// {
//   product_name: "Mobile & DTH Recharge App",
//   product_image: mobileAndDTH,
//   about_product:
//     "Mobile and DTH Recharge App for Distributor, Channel Partners and Retailers",
//   explore_more_button:  <Button colorScheme="twitter" size={"sm"}>
// Explore More
// </Button>,
// },
// {
//   product_name: "Multi Recharge System",
//   product_image: mobileRecharge,
//   about_product:
//     "We provide Software Product to setup multi-reacharge automatic server System for all mobiles (Prepaid & Postpaid) & DTH(TV) Oparetors",
//   explore_more_button:  <Button colorScheme="twitter" size={"sm"}>
// Explore More
// </Button>,
// },
// {
//   product_name: "School ERP Software",
//   product_image: schoolErP,
//   about_product: "  Complate Solution for functions related to School",
//   explore_more_button:  <Button colorScheme="twitter" size={"sm"}>
// Explore More
// </Button>,
// },
