import React from "react";
import IT_service_banner from "../Common/IT_service_banner";
import uiux_banner from "./UIUX_Images/uiux_banner.png";

const UIUX_banner = () => {
  return (
    <IT_service_banner
      service_or_product_image={uiux_banner}
      service_or_product_text={"UI/UX"}
      service_or_product_development_text={"Design & Development"}
      service_or_product_tagline={
        "Unlock intuitive interfaces and seamless experiences with our expert UI/UX design and development."
      }
    />
  );
};

export default UIUX_banner;
