import React from "react";
import styles from "./am.module.css";
import IT_services_banner_content from "../Common/IT_services_banner_content";
import am_card_img from "./AM_images/am_card_image.png";

const AM_collaborate = () => {
  const first_line = () => {
    return (
      <>
        Unlock the full potential of your digital presence with{" "}
        <strong>Novity's </strong>
        application maintenance services.
      </>
    );
  };
  const second_line =
    "Our seasoned team brings years of expertise to ensure your app remains not just functional, but consistently dynamic and engaging.";
  const third_line = () => {
    return (
      <>
        Whether you're a burgeoning startup or a well-established enterprise,
        our tailored maintenance solutions are designed to sustain user
        engagement, elevate downloads, and drive your business forward.
        <br />
        <p style={{ color: "white" }}>
          With Novity, your app isn't just a product—it's a resilient asset
          primed for ongoing growth and success.
        </p>
      </>
    );
  };

  const quotes_grid = [
    {
      quotes_heading: "Proactive Updates: ",
      quotes_content:
        "Implementing timely updates and enhancements to keep your application aligned with evolving user needs and technological advancements.",
    },
    {
      quotes_heading: "Performance Optimization: ",
      quotes_content:
        "Continuously monitoring and optimizing your application's performance to ensure smooth operation and enhanced user satisfaction.",
    },
    {
      quotes_heading: "Scalability Solutions: ",
      quotes_content:
        "Offering scalable solutions to accommodate increasing user demands and ensure seamless operation as your user base grows.",
    },
  ];

  return (
    <section className={styles.am_banner_2}>
      <div className={styles.am_bgImage}></div>
      <IT_services_banner_content
        first_line={first_line()}
        second_line={second_line}
        third_line={third_line()}
        card_image={am_card_img}
      />
    </section>
  );
};

export default AM_collaborate;
