import React from "react";
import Product_header_banner from "../Common/Product_header_banner";
import api_solution from "./API_SolImages/API.png";

const API_Header = () => {
  return (
    <>
      <Product_header_banner
        p_image={api_solution}
        p_service_name={"API"}
        p_fs={"Solutions"}
        p_tagline={
          "Unlock seamless integration with our powerful API solutions."
        }
      />
    </>
  );
};

export default API_Header;
