import React from "react";
import IT_service_banner from "../Common/IT_service_banner";
import aad_banner from "./AADImages/aad_banner.png";

const AAD_Banner = () => {
  return (
    <IT_service_banner
      service_or_product_image={aad_banner}
      service_or_product_text={"Android App"}
      service_or_product_development_text={"Development"}
      service_or_product_tagline={
        "Transform your Android vision into reality with our custom app development expertise."
      }
    />
  );
};

export default AAD_Banner;
