import React from "react";
import Product_header_banner from "../Common/Product_header_banner";
import travel_service from "./Travel_Services_Images/travel_services.png";

const Travel_Services_Header = () => {
  return (
    <>
      <Product_header_banner
        p_image={travel_service}
        p_service_name={"Travel"}
        p_fs={"Services"}
        p_tagline={
          "Elevate travel experiences with our seamless booking system solutions."
        }
      />
    </>
  );
};

export default Travel_Services_Header;
