import React from "react";
import styles from "./b2c_sol.module.css";
import Product_Details from "../Common/Product_Details";
import b2c from "./B2C_Images/B-TO-C.jpg";

const B2C_solution_details = () => {
  return (
    <section className={styles.b2c_sol_details}>
      <div className={styles.b2c_sol_details_bgImage}></div>
      <Product_Details
        img_detail={b2c}
        detail_1={
          "Experience the power of our B2C software and fintech solutions, designed to elevate your customers' experience while accelerating your business growth."
        }
        detail_2={
          "Enhance customer satisfaction, drive expansion, and maintain a competitive edge in today's dynamic market."
        }
        detail_3={
          "Join us in the journey of innovation, where your business profits soar and your brand value flourishes. With our comprehensive software and fintech solutions, you'll always be moving forward."
        }
      />
    </section>
  );
};

export default B2C_solution_details;
