import React from "react";
import Product_Details from "../Common/Product_Details";
import payment_col_API_image from "./Payment_Collection_API_Images/payment_collecation_img.png";
import styles from "./payment_col_api.module.css";

const Payment_collecation_API_details = () => {
  return (
    <section className={styles.payment_col_details}>
      <div className={styles.payment_col_details_bgImage}></div>
      <Product_Details
        img_detail={payment_col_API_image}
        detail_1={
          "Empower your business with our cutting-edge payment collection API, offering seamless support for diverse payment methods and currencies."
        }
        detail_2={
          "Novity's API solutions enable effortless integration of card payments, bank transfers, e-wallet transactions and more, providing a unified platform for streamlined payment processing."
        }
        detail_3={
          "With advanced security measures in place, trust Novity to deliver secure and customizable solutions tailored to your business needs. Accelerate your payment collection process with our lightning-fast API solutions."
        }
      />
    </section>
  );
};

export default Payment_collecation_API_details;
