import React from "react";
import Navbar from "../../Home/Header/Navbar";
import { Heading } from "@chakra-ui/react";

const Testimonial = () => {
  return (
    <>
      <Navbar />
      <Heading>Testimonial page</Heading>
    </>
  );
};

export default Testimonial;
