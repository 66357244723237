import React from "react";
import Navbar from "../Home/Header/Navbar";
import { Heading } from "@chakra-ui/react";
import styles from "./blog.module.css"

const Blog = () => {
  return (
    <>
      <Navbar />
      <section className={styles.container} >
      <Heading></Heading>
      </section>
    </>
  );
};

export default Blog;
