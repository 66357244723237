import AboutUs from "./Components/Company/About_Us/AboutUs";
import Career from "./Components/Company/Career/Career";
import Testimonial from "./Components/Company/Testimonials/Testimonial";
import Product_Development from "./Components/ITServices/Product_Development/Product_Development.jsx";
import Web_Development from "./Components/ITServices/Web_Develpment/Web_Development";
import App_Maintanance from "./Components/ITServices/App_Maintanance/App_Maintanance";
import Android_App_Development from "./Components/ITServices/Android_App_Development/Android_App_Development";
// import WebSite_Development from "./Components/ITServices/WebSite_Development/WebSite_Development.jsx";
// import Contact_Us from "./Components/ContactUs/Contact_Us";
import Blog from "./Components/Blog/Blog.jsx";
// import Fintech_services from "./Components/Fintech_Services/Fintech_services";
import UIUX_Design from "./Components/ITServices/UIUX_Development/UIUX_Design";

//Images
import about_us from "./Images/about_us.png";
import career from "./Images/career.png";
import testimonial from "./Images/testimonial.png";
import product_development from "./Components/Home/OurServices/OurServicesImages/product_development.png";
// import web_development from "./Components/Home/OurServices/OurServicesImages/web_development.png";
import website_development from "./Components/Home/OurServices/OurServicesImages/website_development.png";
import anndoid_app_development from "./Components/Home/OurServices/OurServicesImages/android_development.png";
import app_maintanance from "./Components/Home/OurServices/OurServicesImages/application_maintanance.png";
import uiux from "./Components/Home/OurServices/OurServicesImages/uiuxdesign.png";
// import neo_bank from "./Images/neo_banking.png";
// import aeps from "./Images/aeps.png";
// import bbps from "./Images/bbps.png";
// import matm from "./Images/matm.png";
// import cms from "./Images/cms.png";
// import money_transfer from "./Images/money_transfer.png";
// import adhaar_pay from "./Images/adhaara_pay.png";
// import loan_repayment from "./Images/loan_repayment.png";
// import credit_card from "./Images/credit_card.png";
import b2b from "./Images/B2B.png";
import b2c from "./Images/b2c.png";
import travel from "./Images/travel.png";
import e_commerce from "./Images/e-commerce.png";
// import govt_services from "./Images/govt-services.png";
import api_solution from "./Images/api_solution.png";
import e_kyc from "./Images/e-kyc.png";
import payment_gateway from "./Images/payment_gateway.png";
import payment_collaction from "./Images/payement_collaction_api.png";
// import insurance from "./Images/insurance.png";
// import Neo_Banking from "./Components/Fintech_Services/Neo_Banking/Neo_Banking";
// import Money_Transfer from "./Components/Fintech_Services/Money_Transfer/Money_Transfer";
// import Credit_Card from "./Components/Fintech_Services/Credit_Card/Credit_Card";
// import AEPS from "./Components/Fintech_Services/AEPS/AEPS";
// import BBPS from "./Components/Fintech_Services/BBPS/BBPS";
// import MATM from "./Components/Fintech_Services/MATM/MATM";
// import Loan_Repayment from "./Components/Fintech_Services/Loan_Repayment/Loan_Repayment";
// import Insurance from "./Components/Fintech_Services/Insurance/Insurance";
// import CMS from "./Components/Fintech_Services/CMS/CMS";
import B2B_Solution from "./Components/Products/B2B_Solution/B2B_Solution";
import B2C_Solution from "./Components/Products/B2C_Solution/B2C_Solution";
import API_solution from "./Components/Products/API_Solution/API_solution";
import Travel_Services from "./Components/Products/Travel_Services/Travel_Services";
import E_Commerce from "./Components/Products/E_Commerce/E_Commerce";
// import Govt_Services from "./Components/Products/Govt_Services/Govt_Services";
import E_KYC from "./Components/Products/E_KYC/E_KYC";
import Payment_Gateway from "./Components/Products/Payment_Gateway/Payment_Gateway";
import Payment_Collection_API from "./Components/Products/Payment_Collection_API/Payment_Collection_API";

export const routes = [
  {
    name: "Services",
    href: "#our_services",
    submenu: [
      {
        name: "Product Development",
        component: <Product_Development />,
        route: "/product-development",
        icon: product_development,
      },
      {
        name: "Web Development",
        component: <Web_Development />,
        route: "/web-development",
        icon: website_development,
      },
      {
        name: "Android Appplication Development",
        component: <Android_App_Development />,
        route: "/android-app-development",
        icon: anndoid_app_development,
      },
      {
        name: "Application Maintanance",
        component: <App_Maintanance />,
        route: "/app-maintanance",
        icon: app_maintanance,
      },
      {
        name: "UI/UX Design",
        component: <UIUX_Design />,
        route: "/ui_ux-design",
        icon: uiux,
      },
    ],
  },
  // {
  //   name: "FinTech Services",
  //   href: "#fintech_services",
  //   submenu: [
  //     {
  //       name: "Neo Banking",
  //       component: <Neo_Banking />,
  //       route: "/neo-banking",
  //       icon: neo_bank,
  //     },
  //     {
  //       name: "Money Transfer",
  //       component: <Money_Transfer />,
  //       route: "/money-transfer",
  //       icon: money_transfer,
  //     },
  //     {
  //       name: "Credit Card",
  //       component: <Credit_Card />,
  //       route: "/credit-card",
  //       icon: credit_card,
  //     },
  //     {
  //       name: "AEPS",
  //       component: <AEPS />,
  //       route: "/aeps",
  //       icon: aeps,
  //     },
  //     {
  //       name: "BBPS",
  //       component: <BBPS />,
  //       route: "/bbps",
  //       icon: bbps,
  //     },
  //     {
  //       name: "MATM",
  //       component: <MATM />,
  //       route: "/matm",
  //       icon: matm,
  //     },
  //     {
  //       name: "Loan Repayment",
  //       component: <Loan_Repayment />,
  //       route: "/loan-repayment",
  //       icon: loan_repayment,
  //     },
  //     {
  //       name: "Insurance",
  //       component: <Insurance />,
  //       route: "/insurance",
  //       icon: insurance,
  //     },
  //     {
  //       name: "CMS",
  //       component: <CMS />,
  //       route: "/cms",
  //       icon: cms,
  //     },
  //   ],
  // },
  {
    name: "Products",
    href: "#our_products",
    submenu: [
      {
        name: "B2B Solution",
        component: <B2B_Solution />,
        route: "/b2b",
        icon: b2b,
      },
      {
        name: "B2C Solution",
        component: <B2C_Solution />,
        route: "/b2c",
        icon: b2c,
      },
      {
        name: "API Solutions",
        component: <API_solution />,
        route: "/api-solutions",
        icon: api_solution,
      },
      {
        name: "Travel Services",
        component: <Travel_Services />,
        route: "/travel-services",
        icon: travel,
      },
      {
        name: "E-Commerce",
        component: <E_Commerce />,
        route: "/e-commerce",
        icon: e_commerce,
      },
      // {
      //   name: "Goverment services",
      //   component: <Govt_Services />,
      //   route: "/govt-services",
      //   icon: govt_services,
      // },
      {
        name: "E-KYC Solution",
        component: <E_KYC />,
        route: "/e-kyc-solution",
        icon: e_kyc,
      },
      {
        name: "Payment Gateway",
        component: <Payment_Gateway />,
        route: "/payment-gateway",
        icon: payment_gateway,
      },
      {
        name: "Payment Collection API",
        component: <Payment_Collection_API />,
        route: "/payment-colletion-api",
        icon: payment_collaction,
      },
    ],
  },
  {
    name: "Company",
    submenu: [
      {
        name: "About Us",
        component: <AboutUs />,
        route: "/about-us",
        icon: about_us,
      },
      {
        name: "Career",
        component: <Career />,
        route: "/career",
        icon: career,
      },
      {
        name: "Testimonial",
        component: <Testimonial />,
        route: "/testimonial",
        icon: testimonial,
      },
    ],
  },
  // {
  //   name: "Contact us",
  //   component: <Contact_Us />,
  //   route: "/contact-us",
  // },
  {
    name: "Blog",
    component: <Blog />,
    route: "blogs",
  },
];
