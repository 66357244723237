import React from "react";
import travel_service from "./Travel_Services_Images/travel_service_image.png";
import Product_Details from "../Common/Product_Details";
import styles from "./travel_service.module.css";

const Travel_services_details = () => {
  return (
    <section className={styles.travel_services_details}>
      <div className={styles.travel_services_details_bgImage}></div>
      <Product_Details
        img_detail={travel_service}
        detail_1={
          "Elevate the travel experience for your customers with our cutting-edge travel booking service development solutions."
        }
        detail_2={
          "Crafted with your customers in mind, our expert offerings aim to enhance their journey while accelerating your business growth. Improve customer satisfaction, drive bookings, and stay ahead in the competitive travel industry."
        }
        detail_3={
          "Join us in innovation, where your business profits soar and your brand value flourishes. With our comprehensive travel booking service development solutions, you'll always be moving forward."
        }
      />
    </section>
  );
};

export default Travel_services_details;
