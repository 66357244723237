import React from "react";
import Features_section from "../Common/Features_section";

const Payment_gateway_features = () => {
  const paymentGatewayFeatures = {
    title: "Payment Gateway",
    description:
      "Explore our key features designed to provide secure and efficient payment processing solutions.",
    features: [
      {
        name: "Security Solutions",
        description:
          "Implement robust security measures to safeguard sensitive payment information and prevent unauthorized access.",
      },
      {
        name: "Customized Reporting",
        description:
          "Access detailed and customizable reports to gain insights into your payment transactions and track performance metrics.",
      },
      {
        name: "Billing",
        description:
          "Streamline billing processes with automated invoicing, billing schedules, and recurring payments for improved efficiency.",
      },
      {
        name: "Online Payment Processing",
        description:
          "Enable seamless online payment processing for your customers, supporting various payment methods and currencies.",
      },
    ],
  };

  return (
    <>
      <Features_section features={paymentGatewayFeatures} />
    </>
  );
};

export default Payment_gateway_features;
