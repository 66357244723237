import React from "react";
import Product_header_banner from "../Common/Product_header_banner";
import pg_header from "./Payment_Gateway_Images/payment_gateway.png";

const Payment_Gateway_Header = () => {
  return (
    <>
      <Product_header_banner
        p_image={pg_header}
        p_service_name={"Payment"}
        p_fs={"Gateway"}
        p_tagline={
          "Drive secure transactions with our customized payment gateway solutions."
        }
      />
    </>
  );
};

export default Payment_Gateway_Header;
