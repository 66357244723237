import React from "react";
import "./navbar.css";
import logo from "./NavbarImages/novity-technologies-llp-logo.png";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { routes } from "../../../routes";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <nav className="navbar desktop-navbar">
      <div className="logo">
        <a href="/home">
          <img src={logo} alt="Novity" />
        </a>
      </div>

      <div className="container green borderXwidth">
        {routes.map((menu, index) => {
          return (
            <div className="dropdown_container" key={index}>
              <a href={menu.route} className="hover_here">
                {menu.name}
              </a>
              {menu.submenu ? (
                <div className="dropdown">
                  {menu.submenu.map((menuItem, index) => {
                    console.log(menuItem);
                    return (
                      <div>
                        <a href={menuItem.route} key={index}>
                          <img
                            src={menuItem.icon}
                            alt={menuItem.name}
                            className="icon"
                          />
                          <p>{menuItem.name}</p>
                        </a>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      {/* <div>
        <button class="button-19" role="button">
          Login
        </button>
      </div> */}
      <div className="mobile_navbar">
        <Button
          as={IconButton}
          ref={btnRef}
          colorScheme="blue"
          onClick={onOpen}
          icon={<HamburgerIcon />}
        ></Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <div className="mobile_container">
                <div className="mobile_dropdown_container">
                  <a href="#" className="hover_here">
                    Company
                  </a>
                  <div className="dropdown">
                    <a href="/about-us">About Us</a>
                    <a href="/career">Career</a>
                    <a href="#">Testimonial</a>
                  </div>
                </div>
                <div className="mobile_dropdown_container nav-link">
                  <a href="#" class="hover_here">
                    Services
                  </a>
                  <div className="dropdown">
                    <a href="/product-development">Product Development</a>
                    <a href="/web-development">Web Development</a>
                    <a href="/android-app-development">
                      Android Application Development{" "}
                    </a>
                    <a href="/app-maintanance">Application Maintanance</a>
                    <a href="/ui_ux-design">Website Developement</a>
                  </div>
                </div>
                <div className="nav-link">
                  <a href="contact-us">Contact Us</a>
                </div>
                <div className="nav-link">
                  <a href="/blog">Blog</a>
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
      <div>
        <a href="/contact-us">
          <button className="button-19">Contact Us</button>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
