import React from "react";
import Navbar from "./Header/Navbar";
import BannerSection from "./BannerSection/BannerSection";
import OurServicesSection from "./OurServices/OurServicesSection";
// import Banner2 from "./BannerSection/Banner2";
import OurProductSection from "./OurProduct/OurProductSection";
import WhyUs from "./WhyUs/WhyUs";
import Footer from "./Footer/Footer";
import Footer_contact from "./Footer/Footer_contact";
import WhatsOnYourMind from "./WOM/WhatsOnYourMind";
// import Fintech_services from "./FinTechServices/Fintech_Services";
import SupportSection from "./BannerSection/SupportSection";

const HomePage = () => {
  
  return (
    <>
      <Navbar />
      <BannerSection />
      <SupportSection/>
      {/* <Banner2 /> */}
      <OurServicesSection />
      {/* <Fintech_services /> */}
      <OurProductSection />
      <WhyUs />
      <WhatsOnYourMind />
      <Footer />
      <Footer_contact />
    </>
  );
};

export default HomePage;
