import React from "react";
import { Heading } from "@chakra-ui/react";
import styles from "./supportsection.module.css";
import exp from "./BannerImages/exp.png"
import happyClient from "./BannerImages/happyclients.png"
import support from "./BannerImages/247support.png"

const SupportSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.supportSection}>
       
        <div>
          <img src={exp} alt="" />
          <div>
          <Heading as={"h2"} size={"xl"}>
            10+
          </Heading>
          <p>Years Experience</p>
          </div>
        </div>
        <div>
          <img src={happyClient} alt="" />
          <div>

          <Heading as={"h2"} size={"lg"}>
            300+
          </Heading>
          <p>Happy Clients</p>
          </div>
        </div>
        <div>
          <img src={support} alt="" />
          <div>
          <Heading as={"h2"} size={"lg"}>
           24/7
          </Heading>
          <p>Online Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportSection;
