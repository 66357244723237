import React from "react";
import b2b_img_detail from "./B2B_Images/b2b_image.png";
import styles from "./b2b_solution.module.css";
import Product_Details from "../Common/Product_Details";

const B2B_Solution_Details = () => {
  return (
    <section className={styles.b2b_sol_details}>
      <div className={styles.b2b_sol_details_bgImage}></div>
      <Product_Details
        img_detail={b2b_img_detail}
        detail_1={
          "Unlock the potential of your business with our B2B solution software and fintech services. Empower developers to harness the functionality of diverse software systems, services, or platforms to create sophisticated applications. "
        }
        detail_2={
          "Our comprehensive solutions facilitate seamless integration between various systems, enabling efficient data and resource sharing. "
        }
        detail_3={
          " Step into the realm of modern software development and innovation with ease, as we support you in creating feature-rich applications tailored to your business needs."
        }
      />
    </section>
  );
};

export default B2B_Solution_Details;
